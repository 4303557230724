<template>
    <setting-layout
        :title="$t('pages.user.setting.creator.title')"
        :back="{ name: 'user.own.profile.view' }"
    >
        <v-container class="pt-2 mt-4">
            <div
                v-for="(item, index) in menus"
                :key="index"
                class="link text-decoration-none"
                @click="handleClickItem(item)"
            >
                <v-row class="ml-0 mr-0 item pl-3 mt-3">
                    <v-col cols="10">
                        {{ item.text }}
                    </v-col>
                    <v-col v-if="!item.locked">
                        <icon-great />
                    </v-col>
                    <v-col v-else>
                        <div
                            style="
                                display: flex;
                                height: 100%;
                                align-items: center;
                            "
                        >
                            <icon-locked />
                        </div>
                    </v-col>
                </v-row>
            </div>
        </v-container>
        <inform-dialog
            :text="
                !user.verified
                    ? $t('dialogs.unlock_verify_account')
                    : clickedItem.to === 'user.setting.creator.streams'
                    ? $t('dialogs.streams_restricted')
                    : ''
            "
            v-if="showInformDialog"
            @close="handleCloseInformDialog"
        />
    </setting-layout>
</template>

<script>
import SettingLayout from "@/layouts/app/profile/SettingLayout";
import IconLocked from "@/assets/images/icons/locked.svg?inline";
import IconGreat from "@/assets/images/icons/great.svg?inline";
import InformDialog from "@/components/app/dialogs/InformDialog";

import { mapState } from "vuex";

export default {
    name: "Creator",
    data: () => ({
        showInformDialog: false,
        clickedItem: null,
    }),
    computed: {
        ...mapState({
            user: (state) => state.user.user.data,
        }),
        menus() {
            return [
                // {text: this.$t('pages.user.setting.creator.verify_yoti.verify_account'), locked: false, to: 'user.setting.creator.verify'},
                // {text: this.$t('pages.user.setting.creator.verify_yoti.title'), locked: false, to: 'user.setting.creator.verifyYoti'},
                {
                    text: this.$t(
                        "pages.user.setting.creator.verify_ondato.title"
                    ),
                    locked: false,
                    to: "user.setting.creator.verifyOndato",
                },
                {
                    text: this.$t("pages.user.setting.creator.agreement.title"),
                    locked: !this.user.verifyOndatoAt,
                    to: "user.setting.creator.agreement",
                },
                {
                    text: this.$t(
                        "pages.user.setting.creator.withdraw_options.title"
                    ),
                    locked: !this.user.verified,
                    to: "user.setting.creator.withdraw",
                },
                {
                    text: this.$t("pages.user.setting.creator.streams.title"),
                    locked:
                        !this.user.verified || this.user.streams_restricted_at,
                    to: "user.setting.creator.streams",
                },
                {
                    text: this.$t(
                        "pages.user.setting.creator.subscriptions.title"
                    ),
                    locked: !this.user.verified,
                    to: "user.setting.creator.subscription",
                },
                {
                    text: this.$t("pages.user.setting.creator.holiday_mood.title"),
                    locked: !this.user.verified,
                    to: "user.setting.creator.holiday_mood",
                },
                {
                    text: this.$t("pages.user.setting.creator.dream_goal.title"),
                    locked: !this.user.verified,
                    to: "user.setting.creator.dream_goal",
                },
                {
                    text: this.$t("pages.user.setting.creator.promotion.title"),
                    locked: !this.user.verified,
                    to: "user.setting.creator.promotion",
                },
                {
                    text: this.$t("pages.user.setting.creator.tip_menu.title"),
                    locked: !this.user.verified,
                    to: "user.setting.creator.tips",
                },
                {
                    text: this.$t("pages.user.setting.creator.messages.title"),
                    locked: !this.user.verified,
                    to: "user.setting.creator.messages",
                },
                {
                    text: this.$t("pages.user.setting.creator.referrals.title"),
                    locked: !this.user.verified,
                    to: "user.setting.creator.referrals",
                },
            ];
        },
    },
    methods: {
        handleClickItem(item) {
            if (item.locked) {
                this.clickedItem = item;
                this.showInformDialog = true;
                return;
            }

            this.$router.push({ name: item.to });
        },
        handleCloseInformDialog() {
            this.showInformDialog = false;
            this.clickedItem = null;
        },
    },
    mounted() {
        this.$mixpanel.identifyUser(this.user.email);
        this.$mixpanel.pageViewed('Noodzler');
    },
    components: {
        SettingLayout,
        IconLocked,
        IconGreat,
        InformDialog,
    },
};
</script>

<style scoped>
.item {
    background: #1a1a1a;
    border-radius: 3px;
}
</style>
